// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  min-height: 60px;
  background: var(--color-white);
  border-bottom: 1px solid var(--border);
}

.avatar-wrapper-section-header {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.avatar-wrapper-section-header img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.avatar-wrapper-section-header .sb-avatar__text div span {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.ProfileWrapper {
  flex: 1 1;
  cursor: pointer;
}

.Name {
  color: var(--dark);
  font-size: 1rem;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subtitle-header {
  color: var(--secondary);
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,8BAAA;EACA,sCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,0BAAA;EACA,2BAAA;AACF;;AAEA;EACE,SAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".container-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 60px;\n  padding: 10px;\n  min-height: 60px;\n  background: var(--color-white);\n  border-bottom: 1px solid var(--border);\n}\n\n.avatar-wrapper-section-header {\n  width: 40px;\n  height: 40px;\n  margin-right: 10px;\n  cursor: pointer;\n}\n\n.avatar-wrapper-section-header img {\n  border-radius: 50%;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n}\n\n.avatar-wrapper-section-header .sb-avatar__text div span {\n  font-size: 1rem !important;\n  font-weight: 500 !important;\n}\n\n.ProfileWrapper {\n  flex: 1;\n  cursor: pointer;\n}\n\n.Name {\n  color: var(--dark);\n  font-size: 1rem;\n  margin-bottom: 2px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.subtitle-header {\n  color: var(--secondary);\n  font-size: 0.75rem;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
