// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-history-list {
  width: 45px;
  height: 45px;
}

.call-history-list img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/CallHistory/CallHistory.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAEF","sourcesContent":[".call-history-list {\n  width: 45px;\n  height: 45px;\n}\n.call-history-list img {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
