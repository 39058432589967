// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-loader {
  width: 100%;
  height: 100%;
  background-color: var(--modal-backdrop-bg-2);
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
}

.chat-gif {
  width: 200px;
  background-color: var(--color-white);
  border-radius: 10px;
}

.chat-loading-text {
  left: 50%;
  top: 17%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 4.4px;
  text-transform: capitalize;
  position: absolute;
  overflow: hidden;
  transform: translate(-40%, -60%);
}

.chat-loading-text:before {
  color: var(--secondary);
  content: attr(data-loading-text);
}

.chat-loading-text:after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: rgba(82, 79, 79, 0.6);
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  animation: chat-loading-text 5s infinite;
}
@keyframes chat-loading-text {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/ChatLoadingPage/ChatLoader.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,4CAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AACA;EACE,YAAA;EAEA,oCAAA;EACA,mBAAA;AACF;;AAEA;EACE,SAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,gCAAA;AACF;;AAEA;EACE,uBAAA;EACA,gCAAA;AACF;;AACA;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,4BAAA;EACA,gBAAA;EACA,kBAAA;EACA,gCAAA;EAEA,wCAAA;AAEF;AAQA;EACE;IACE,QAAA;EAGF;EADA;IACE,WAAA;EAGF;AACF","sourcesContent":[".chat-loader {\n  width: 100%;\n  height: 100%;\n  background-color: var(--modal-backdrop-bg-2);\n  border-radius: 7px;\n  position: absolute;\n  z-index: 9999;\n}\n.chat-gif {\n  width: 200px;\n  //   height: 200px;\n  background-color: var(--color-white);\n  border-radius: 10px;\n}\n\n.chat-loading-text {\n  left: 50%;\n  top: 17%;\n  font-size: 14px;\n  font-weight: bold;\n  letter-spacing: 4.4px;\n  text-transform: capitalize;\n  position: absolute;\n  overflow: hidden;\n  transform: translate(-40%, -60%);\n}\n\n.chat-loading-text:before {\n  color: var(--secondary);\n  content: attr(data-loading-text);\n}\n.chat-loading-text:after {\n  top: 0;\n  left: 0;\n  width: 0;\n  opacity: 1;\n  color: #524f4f99;\n  overflow: hidden;\n  position: absolute;\n  content: attr(data-loading-text);\n  -webkit-animation: chat-loading-text 5s infinite;\n  animation: chat-loading-text 5s infinite;\n}\n@-webkit-keyframes chat-loading-text {\n  0% {\n    width: 0;\n  }\n  100% {\n    width: 100%;\n  }\n}\n@keyframes chat-loading-text {\n  0% {\n    width: 0;\n  }\n  100% {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
