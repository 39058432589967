// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-popup-dark .modal-dialog .modal-content {
  background: rgb(0, 0, 0);
  color: var(--color-white);
}

.call-popup-dark .modal-dialog .modal-content {
  background: rgb(0, 0, 0);
  color: var(--color-white);
}

.theme-btn {
  background: var(--color-white);
  color: var(--dark);
  width: 50px;
  height: 50px;
  font-size: 20px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.5s;
  cursor: pointer;
}

.call-users {
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/AudioAndVideo/AudioAndVideo.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,yBAAA;AACF;;AACA;EACE,wBAAA;EACA,yBAAA;AAEF;;AACA;EACE,8BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iDAAA;EACA,0BAAA;EACA,eAAA;AAEF;;AAAA;EACE,wBAAA;AAGF","sourcesContent":[".call-popup-dark .modal-dialog .modal-content {\n  background: rgba(0, 0, 0);\n  color: var(--color-white);\n}\n.call-popup-dark .modal-dialog .modal-content {\n  background: rgba(0, 0, 0);\n  color: var(--color-white);\n}\n\n.theme-btn {\n  background: var(--color-white);\n  color: var(--dark);\n  width: 50px;\n  height: 50px;\n  font-size: 20px;\n  border-radius: 50%;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  transition: transform 0.5s;\n  cursor: pointer;\n}\n.call-users {\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
