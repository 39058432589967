// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer-count {
  font-size: 50px;
}

.audio-button {
  padding: 0.8rem 2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  color: var(--color-white);
  transition: all 300ms ease-in-out;
}

.btn-audio-success {
  background-color: var(--success);
}

.btn-audio-danger {
  background-color: var(--danger);
  transform: translateY(0);
}

.btn-audio-warning {
  background-color: var(--warning);
  transform: translateY(0);
}

.btn-audio-primary {
  background-color: var(--primary);
  transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/components/RecordView/RecordView.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,oBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,iCAAA;AAEF;;AACA;EACE,gCAAA;AAEF;;AAAA;EACE,+BAAA;EACA,wBAAA;AAGF;;AADA;EACE,gCAAA;EACA,wBAAA;AAIF;;AAFA;EACE,gCAAA;EACA,wBAAA;AAKF","sourcesContent":[".timer-count {\n  font-size: 50px;\n}\n.audio-button {\n  padding: 0.8rem 2rem;\n  border: none;\n  cursor: pointer;\n  border-radius: 5px;\n  font-weight: 600;\n  color: var(--color-white);\n  transition: all 300ms ease-in-out;\n}\n\n.btn-audio-success {\n  background-color: var(--success);\n}\n.btn-audio-danger {\n  background-color: var(--danger);\n  transform: translateY(0);\n}\n.btn-audio-warning {\n  background-color: var(--warning);\n  transform: translateY(0);\n}\n.btn-audio-primary {\n  background-color: var(--primary);\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
