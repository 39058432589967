// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  width: max-content !important;
  padding: 9px 16px !important;
  border-radius: 5px !important;
  text-align: center !important;
  height: 40px;
}

.btn:hover {
  color: var(--titleWhite);
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/components/CommonButton/CommonButton.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,4BAAA;EACA,6BAAA;EACA,6BAAA;EACA,YAAA;AACF;;AACA;EACE,wBAAA;AAEF","sourcesContent":[".btn {\n  width: max-content !important;\n  padding: 9px 16px !important;\n  border-radius: 5px !important;\n  text-align: center !important;\n  height: 40px;\n}\n.btn:hover {\n  color: var(--titleWhite);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
