// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-body {
  min-width: 300px;
  flex: 40% 1;
  border-right: 1px solid var(--border);
  z-index: 1;
}

.footer-container {
  background: var(--color-white);
  border-top: 1px solid var(--border);
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.header-container {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
}

@media screen and (max-width: 912px) {
  .footer-container {
    position: fixed;
  }
  .header-container {
    position: fixed;
  }
}
.ScrollButton {
  position: absolute;
  right: 15px;
  bottom: 100px;
  width: 42px;
  height: 42px;
  z-index: -1;
  border-radius: 50%;
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  z-index: 10;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/ChatPage/ChatPage.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,qCAAA;EACA,UAAA;AACF;;AAEA;EACE,8BAAA;EACA,mCAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,UAAA;AACF;;AACA;EACE;IACE,eAAA;EAEF;EAAA;IACE,eAAA;EAEF;AACF;AACA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".chat-body {\n  min-width: 300px;\n  flex: 40%;\n  border-right: 1px solid var(--border);\n  z-index: 1;\n}\n\n.footer-container {\n  background: var(--color-white);\n  border-top: 1px solid var(--border);\n  position: absolute;\n  width: 100%;\n  bottom: 0px;\n  // min-height: 80px;\n}\n.header-container {\n  position: absolute;\n  width: 100%;\n  top: 0px;\n  z-index: 1;\n}\n@media screen and (max-width: 912px) {\n  .footer-container {\n    position: fixed;\n  }\n  .header-container {\n    position: fixed;\n  }\n}\n\n.ScrollButton {\n  position: absolute;\n  right: 15px;\n  bottom: 100px;\n  width: 42px;\n  height: 42px;\n  z-index: -1;\n  border-radius: 50%;\n  color: var(--primary);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: var(--color-white);\n  box-shadow: var(--box-shadow);\n  z-index: 10;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
