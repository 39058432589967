// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-popup-wrapper {
  background-color: rgba(30, 42, 49, 0.5);
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
}

.delete-popup {
  background-color: var(--color-white);
  margin: 10px;
  padding: 20px;
  width: 550px;
}

@media screen and (max-width: 540px) {
  .delete-popup {
    width: 100%;
    margin: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/components/DeletePopup/DeletePopup.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,aAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;AACF;;AACA;EACE,oCAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAEF;;AAAA;EACE;IACE,WAAA;IACA,YAAA;EAGF;AACF","sourcesContent":[".delete-popup-wrapper {\n  background-color: rgba(30, 42, 49, 0.5);\n  height: 100vh;\n  left: 0;\n  top: 0;\n  width: 100%;\n  position: fixed;\n  z-index: 9999;\n}\n.delete-popup {\n  background-color: var(--color-white);\n  margin: 10px;\n  padding: 20px;\n  width: 550px;\n}\n@media screen and (max-width: 540px) {\n  .delete-popup {\n    width: 100%;\n    margin: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
