// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneInputInput {
  border: 0 !important;
  display: flex !important;
  width: 100%;
  outline: none !important;
  background-color: var(--bs-body-bg) !important
}

.PhoneInputInput:disabled {
  background: #f2f2f2 !important;
}

.PhoneInput {
  display: flex !important;
}

.PhoneInput:disabled {
  background: #f2f2f2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormikPhoneNumber/FormikPhoneNumber.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAwB;EACxB,WAAW;EACX,wBAAwB;EACxB;AACF;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".PhoneInputInput {\n  border: 0 !important;\n  display: flex !important;\n  width: 100%;\n  outline: none !important;\n  background-color: var(--bs-body-bg) !important\n}\n\n.PhoneInputInput:disabled {\n  background: #f2f2f2 !important;\n}\n\n.PhoneInput {\n  display: flex !important;\n}\n\n.PhoneInput:disabled {\n  background: #f2f2f2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
