// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calender_icon {
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
}

.rmdp-wrapper {
  background-color: #ffffff !important;
  box-shadow: var(--box-shadow) !important;
}

.rmdp-panel-body li {
  background-color: var(--primary) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/FormikDateField/FormikDateField.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AACF;;AACA;EACE,oCAAA;EACA,wCAAA;AAEF;;AAAA;EACE,2CAAA;AAGF","sourcesContent":[".calender_icon {\n  position: absolute;\n  right: 13px;\n  top: 10px;\n  cursor: pointer;\n}\n.rmdp-wrapper {\n  background-color: #ffffff !important;\n  box-shadow: var(--box-shadow) !important;\n}\n.rmdp-panel-body li {\n  background-color: var(--primary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
