// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-unselect {
  background: var(--layout-bg-unselect);
  padding: 20px;
  height: 100%;
  flex: 60% 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 6px solid var(--primary);
}

.image-wrapper {
  width: 550px;
  margin-bottom: 20px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.title-unselect {
  color: var(--dark);
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.text-unselect {
  color: var(--grey);
  font-size: 0.9rem;
  font-weight: 500;
  max-width: 500px;
  line-height: 24px;
}
.text-unselect:first-of-type {
  padding-bottom: 30px;
}
.text-unselect:last-of-type {
  padding-top: 10px;
}

.Link {
  margin-left: 5px;
  text-decoration: underline;
}
.Link:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/UnSelectedChatPage/UnSelectedChatPage.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,uCAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,0BAAA;AADF;AAGE;EACE,0BAAA;AADJ","sourcesContent":[".container-unselect {\n  background: var(--layout-bg-unselect);\n  padding: 20px;\n  height: 100%;\n  flex: 60%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  border-bottom: 6px solid var(--primary);\n}\n\n.image-wrapper {\n  width: 550px;\n  margin-bottom: 20px;\n}\n\n.image-wrapper img {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n}\n\n.title-unselect {\n  color: var(--dark);\n  font-size: 2rem;\n  font-weight: 400;\n  margin-bottom: 10px;\n}\n\n.text-unselect {\n  color: var(--grey);\n  font-size: 0.9rem;\n  font-weight: 500;\n  max-width: 500px;\n  line-height: 24px;\n\n  &:first-of-type {\n    padding-bottom: 30px;\n  }\n\n  &:last-of-type {\n    padding-top: 10px;\n  }\n}\n\n.Link {\n  margin-left: 5px;\n  text-decoration: underline;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
