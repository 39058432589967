// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-group-icon {
  height: 200px;
  width: 200px;
  background-color: var(--msg-sent);
  color: var(--color-white);
  font-size: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.add-group-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(30, 42, 49, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.create-new-grp-btn {
  background-color: var(--primary);
  color: var(--color-white);
  height: 48px;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
}

.file-input-group {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 150px;
  opacity: 0;
  cursor: pointer !important;
}

#inputID::placeholder {
  color: #a8a7a7;
  opacity: 1;
}

.emoji-wrapper-group {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/NewGroup/NewGroupPopup.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,iCAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,uCAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AAAA;EACE,gCAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAGF;;AAAA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;EACA,UAAA;EACA,0BAAA;AAGF;;AAAA;EACE,cAAA;EACA,UAAA;AAGF;;AADA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;AAIF","sourcesContent":[".new-group-icon {\n  height: 200px;\n  width: 200px;\n  background-color: var(--msg-sent);\n  color: var(--color-white);\n  font-size: 120px;\n  border-radius: 50%;\n  overflow: hidden;\n}\n.add-group-icon {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  background-color: rgba(30, 42, 49, 0.5);\n  border-radius: 50%;\n  cursor: pointer;\n}\n.create-new-grp-btn {\n  background-color: var(--primary);\n  color: var(--color-white);\n  height: 48px;\n  border-radius: 7px;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.file-input-group {\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  font-size: 150px;\n  opacity: 0;\n  cursor: pointer !important;\n}\n\n#inputID::placeholder {\n  color: #a8a7a7;\n  opacity: 1;\n}\n.emoji-wrapper-group {\n  position: absolute;\n  top: 50px;\n  right: 0px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
