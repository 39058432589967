// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper {
  background: var(--color-white);
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.8s ease;
  transition-delay: 0.3s;
}

.search-list-left {
  left: 30px;
}

.search-modal-left {
  left: 20px;
}

.search-wrapper-height {
  height: 70px;
}

.input-list-search {
  width: 100%;
  border: none;
  background: var(--color-white);
  color: var(--grey);
  border: none;
  padding: 10px 10px 10px 55px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}
.input-list-search::placeholder {
  color: var(--grey);
}
.input-list-search:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/components/SearchField/SearchField.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,yBAAA;EACA,sBAAA;AAEF;;AAAA;EACE,UAAA;AAGF;;AADA;EACE,UAAA;AAIF;;AADA;EACE,YAAA;AAIF;;AADA;EACE,WAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,6BAAA;AAIF;AAFE;EACE,kBAAA;AAIJ;AADE;EACE,aAAA;AAGJ","sourcesContent":[".search-wrapper {\n  background: var(--color-white);\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n.search-icon {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  transition: all 0.8s ease;\n  transition-delay: 0.3s;\n}\n.search-list-left {\n  left: 30px;\n}\n.search-modal-left {\n  left: 20px;\n}\n\n.search-wrapper-height {\n  height: 70px;\n}\n\n.input-list-search {\n  width: 100%;\n  border: none;\n  background: var(--color-white);\n  color: var(--grey);\n  border: none;\n  padding: 10px 10px 10px 55px;\n  border-radius: 5px;\n  box-shadow: var(--box-shadow);\n\n  &::placeholder {\n    color: var(--grey);\n  }\n\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
