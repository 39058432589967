// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-box {
  position: relative;
  width: 100%;
  max-width: 60px;
  height: 100%;
  /* transition: all 0.5s ease-in-out; */
}

.input-box.open {
  max-width: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  z-index: 1;

}

.input-box input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: all 0.5s ease-in-out;
}

.input-box.open input {
  padding: 0 15px 0 65px;
}

.input-box .search {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background-color: var(--color-white);
  cursor: pointer;
}

.input-box .close-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
}

.input-box.open .close-icon {
  transform: translateY(-50%) rotate(180deg);
  pointer-events: auto;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/MessagesList/MessagesListSearch.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,sBAAsB;EACtB,6BAA6B;EAC7B,UAAU;;AAEZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,0CAA0C;EAC1C,oBAAoB;EACpB,UAAU;AACZ","sourcesContent":["\n.input-box {\n  position: relative;\n  width: 100%;\n  max-width: 60px;\n  height: 100%;\n  /* transition: all 0.5s ease-in-out; */\n}\n\n.input-box.open {\n  max-width: 100%;\n  width: 100%;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  background-color: #fff;\n  box-shadow: var(--box-shadow);\n  z-index: 1;\n\n}\n\n.input-box input {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  padding: 0 15px;\n  border: none;\n  border-radius: 6px;\n  outline: none;\n  transition: all 0.5s ease-in-out;\n}\n\n.input-box.open input {\n  padding: 0 15px 0 65px;\n}\n\n.input-box .search {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 60px;\n  height: 100%;\n  background-color: var(--color-white);\n  cursor: pointer;\n}\n\n.input-box .close-icon {\n  position: absolute;\n  top: 50%;\n  right: 15px;\n  cursor: pointer;\n  pointer-events: none;\n  opacity: 0;\n}\n\n.input-box.open .close-icon {\n  transform: translateY(-50%) rotate(180deg);\n  pointer-events: auto;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
