// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dropdown-basicActions {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  color: var(--color) !important;
  height: 100% !important;
}

.Actions-drop .dropdown-toggle::after {
  border: 0 !important;
}

.dropdown-menu.show {
  border: none;
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease-in;
  transform: scale(1);
  left: -155px !important;
  min-width: 180px;
  padding: 10px;
}

.dropdown-item {
  color: var(--dark) !important;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 0px 5px 0px;
  padding: 10px 15px;
  width: 160px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--msg-sent);
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/livikit/pages/chat/components/OptionsDropdown/OptionsDropdown.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,uBAAA;EACA,uBAAA;EACA,8BAAA;EACA,uBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,YAAA;EACA,6BAAA;EACA,4BAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,aAAA;AACF;;AACA;EACE,6BAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AAEF;;AAAA;;EAEE,iCAAA;EACA,kBAAA;AAGF","sourcesContent":["#dropdown-basicActions {\n  background-color: transparent !important;\n  border: none !important;\n  padding: 0px !important;\n  color: var(--color) !important;\n  height: 100% !important;\n}\n\n.Actions-drop .dropdown-toggle::after {\n  border: 0 !important;\n}\n\n.dropdown-menu.show {\n  border: none;\n  box-shadow: var(--box-shadow);\n  transition: all 0.2s ease-in;\n  transform: scale(1);\n  left: -155px !important;\n  min-width: 180px;\n  padding: 10px;\n}\n.dropdown-item {\n  color: var(--dark) !important;\n  font-size: 0.8rem;\n  font-weight: 600;\n  cursor: pointer;\n  margin: 0px 0px 5px 0px;\n  padding: 10px 15px;\n  width: 160px;\n}\n.dropdown-item:hover,\n.dropdown-item:focus {\n  background-color: var(--msg-sent);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
